import { Constants } from "../../AllConstant/Constant";
import CustomButton from "../../Shared/CustomButton/CustomButton";

function BharatBiggest() {
  return (
    <div className="flex justify-center w-full bg-[#E7E6F8]/40 py-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex flex-col lg:flex-row gap-10 items-center justify-between">
        <div className="lg:w-[30%] text-center lg:text-start ">
          <div className="">
            <b className="text-xl lg:text-4xl">
              Bharat’s{" "}
              <span className="text-purpleColor font-poppins">
                Biggest & Most Trusted
              </span>{" "}
              Educational Platform
            </b>
            <p className="text-sm mt-2">
              Unlock your potential by signing up with {Constants?.WEB_NAME} The
              most affordable learning solution
            </p>
          </div>
          <CustomButton
            label={`Get Started`}
            className={`bg-purpleColor text-white !mx-0 !text-lg  !px-10 mt-10`}
          />
        </div>{" "}
        <div className="lg:w-[30%] text-center lg:text-start">
          <img
            src={require("../../Assets/bhatatsComponentImage.png")}
            alt=""
            className="opacity-80"
          />
        </div>
      </div>
    </div>
  );
}

export default BharatBiggest;
