import Toolbar from "../Toolbar/Toolbar";
import MobileToolbar from "../Toolbar/MobileToolbar";
import LightFooter from "../Footer/LightFooter";
import NeetHero from "./NeetHero";

function NeetCourse() {
  return (
    <div>
      <div className="">
        <div className="hidden lg:block">
          <Toolbar />
        </div>
        <div className="lg:hidden block">
          <MobileToolbar />
        </div>
      </div>

      <div className="">
        <NeetHero />
      </div>
      <div className="">
        <LightFooter />
      </div>
    </div>
  );
}

export default NeetCourse;
