import CustomButton from "../../Shared/CustomButton/CustomButton";

function Platform() {
  return (
    <div className="flex justify-center w-full  my-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex  flex-col gap-10 justify-between   rounded-lg text-center py-5">
        <div className=" ">
          <p className="text-3xl lg:text-4xl font-bold">
            A Platform Trusted by Students Worldwide
          </p>
          <p className="text-xl">
            Don't Just Take Our Word for It. Delve into the Numbers and Witness
            the Excellence for Yourself!
          </p>
        </div>
        <div className="flex justify-between flex-col lg:flex-row">
          <div className="flex justify-between ">
            <div className="bg-[#FFF2E3] p-5 rounded-xl w-[49%] lg:w-[49%] h-[200px] lg:h-[300px] pt-[10%] mb-5 overflow-hidden">
              <p className="text-3xl lg:text-4xl font-bold">15 Million + </p>
              <p className="">Happy Student</p>
              <img
                src={require("../../Assets/student.png")}
                alt=""
                className="pt-[100%] hover:pt-0 duration-200 "
              />
            </div>
            <div className="bg-[#FFEEED] p-5 rounded-xl  w-[49%] lg:w-[49%] h-[200px] lg:h-[300px] pt-[10%] mb-5 overflow-hidden me-1.5">
              <p className="text-4xl font-bold">24000 + </p>
              <p className="">Mock Tests</p>
              <img
                src={require("../../Assets/note.png")}
                alt=""
                className="pt-[100%] hover:pt-0 duration-200 "
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="bg-[#ECE7FF] p-5 rounded-xl  w-[49%] lg:w-[49%] h-[200px] lg:h-[300px] pt-[10%] mb-5 overflow-hidden">
              <p className="text-4xl font-bold">14000 + </p>
              <p className="">Video Lectures</p>
              <img
                src={require("../../Assets/moniter.png")}
                alt=""
                className="pt-[100%] hover:pt-0 duration-200 "
              />
            </div>
            <div className="bg-[#E4F9FF] p-5 rounded-xl  w-[49%] lg:w-[49%] h-[200px] lg:h-[300px] pt-[10%] mb-5 overflow-hidden">
              <p className="text-4xl font-bold">80000 + </p>
              <p className="">Practice Papers</p>
              <img
                src={require("../../Assets/cover-letter.png")}
                alt=""
                className="pt-[100%] hover:pt-0 duration-200 "
              />
            </div>
          </div>
        </div>
        <CustomButton
          label={`Get Started`}
          className={`bg-purpleColor text-white !mx-0 !text-lg w-[20rem] self-center !py-4`}
        />
      </div>
    </div>
  );
}

export default Platform;
