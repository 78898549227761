import { Constants } from "../../AllConstant/Constant";

function StudyResources() {
  return (
    <div className="flex justify-center w-full  my-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex  flex-col gap-10 justify-between   rounded-lg text-center py-5">
        <div className=" ">
          <p className="text-3xl lg:text-4xl font-bold">Study Resources</p>
          <p className="text-xl">
            A diverse array of learning materials to enhance your educational
            journey.
          </p>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="w-full lg:w-[30%] mb-5 bg-[#F1FAFE] rounded-2xl p-5 cursor-pointer text-start flex flex-col items-center gap-5 overflow-hidden inline-block">
            <div className="">
              <p className="text-4xl font-bold">Notes</p>
              <p className="text-sm my-3">
                Use {Constants?.WEB_NAME} detailed study materials that simplify
                complex ideas into easily understandable language
              </p>
            </div>
            <img
              src={require("../../Assets/pencil.png")}
              alt=""
              className="w-[10rem] self-center hover:scale-110  duration-300"
            />
          </div>{" "}
          <div className="w-full lg:w-[30%] mb-5 bg-[#FFF9EE] rounded-2xl p-5 cursor-pointer text-start flex flex-col items-center gap-5 overflow-hidden inline-block">
            <div className="">
              <p className="text-4xl font-bold">Reference Books </p>
              <p className="text-sm my-3">
                Our experts have created thorough study materials that break
                down complicated concepts into easily understandable content
              </p>
            </div>
            <img
              src={require("../../Assets/books.png")}
              alt=""
              className="w-[10rem] self-center hover:scale-110  duration-300"
            />
          </div>{" "}
          <div className="w-full lg:w-[30%] mb-5 bg-[#E7FFF5] rounded-2xl p-5 cursor-pointer text-start flex flex-col items-center gap-5 overflow-hidden inline-block">
            <div className="">
              <p className="text-4xl font-bold"> NCERT Solutions</p>
              <p className="text-sm my-3">
                Unlock academic excellence with {Constants?.WEB_NAME} NCERT
                Solutions which provides you step-by-step solutions
              </p>
            </div>
            <img
              src={require("../../Assets/solution.png")}
              alt=""
              className="w-[10rem] self-center hover:scale-110  duration-300"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyResources;
