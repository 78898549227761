import { useState } from "react";
import { Constants } from "../../AllConstant/Constant";
import { MdOutlineArrowForward } from "react-icons/md";

import doctor from "../../Assets/doctor.png";

function ExamCategories() {
  const [exploreColorChaneg, setExploreColorChanege] = useState();
  const cardData = [
    {
      img: doctor,
      title: "neet",
      batch: [
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
      ],
    },
    {
      img: doctor,
      title: "neet",
      batch: [
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
      ],
    },
    {
      img: doctor,
      title: "neet",
      batch: [
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
      ],
    },
    {
      img: doctor,
      title: "neet",
      batch: [
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
      ],
    },
    {
      img: doctor,
      title: "neet",
      batch: [
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
      ],
    },
    {
      img: doctor,
      title: "neet",
      batch: [
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
        {
          batchName: "Class 11",
        },
      ],
    },
  ];
  return (
    <div className="flex justify-center w-full   py-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex  flex-col gap-10 justify-between   rounded-lg">
        <div className="flex flex-col items-center text-center w-full">
          <p className="text-4xl font-bold mb-3">Exam Categories</p>
          <p className="text-sm lg:text-lg ">
            {Constants?.WEB_NAME} is preparing students for 18 exam categories.
            Scroll down to find the one you are preparing for
          </p>
        </div>
        <div className="w-full flex flex-wrap justify-between ">
          {cardData?.map((card, index) => (
            <div
              className="flex  border rounded-xl w-[98%] tablet:w-[49%] lg:w-[32.5%] mb-5"
              key={index}
            >
              <div className="lg:w-[60%] flex flex-col gap-5  p-5 ">
                <p className="text-4xl font-bold uppercase">{card?.title}</p>
                <div className="flex flex-wrap gap-3  ">
                  {card?.batch?.map((batchData, index1) => (
                    <p className="border px-3 py-1 text-sm rounded-full w-[80px] cursor-pointer ">
                      Class 11
                    </p>
                  ))}
                </div>
                <div
                  className="flex items-center gap-5  cursor-pointer "
                  onMouseEnter={() => setExploreColorChanege(index)}
                  onMouseLeave={() => setExploreColorChanege(-1)}
                >
                  <p
                    className={`text-xs ${
                      exploreColorChaneg ? "text-purpleColor" : ""
                    }`}
                  >
                    Explore Category
                  </p>
                  <p
                    className={`bg-[#F8F8F8] p-2 px-5 text-xl rounded-full ${
                      exploreColorChaneg === index
                        ? "bg-purpleColor  !text-white"
                        : ""
                    }`}
                  >
                    <MdOutlineArrowForward
                      className={`${
                        exploreColorChaneg === index ? " hover:text-white" : ""
                      }`}
                    />
                  </p>
                </div>
              </div>
              <div className=" lg:w-[40%] bg-[#FFF1F2] flex justify-center items-center p-10 rounded-l-[100px] rounded-r-xl">
                <img
                  src={require("../../Assets/doctor.png")}
                  alt=""
                  className="h-[70px]"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExamCategories;
