import ImageSlider from "../ImageSlider/ImageSlider";
import BharatBiggest from "../BharatBiggest/BharatBiggest";
import Information from "../Information/Information";
import ExamCategories from "../ExamCategories/ExamCategories";
import OfflineCenter from "../OfflineCenter/OfflineCenter";
import Platform from "../Platform/Platform";
import ResultsContainer from "../ResultsContainer/ResultsContainer";
import JoinNow from "../JoinNow/JoinNow";
import LightFooter from "../Footer/LightFooter";
import StudyResources from "../StudyResources/StudyResources";
import StudentContainer from "../StudentContainer/StudentContainer";
import EducationalPlatform from "../EducationalPlatform/EducationalPlatform";
import Toolbar from "../Toolbar/Toolbar";
import MobileToolbar from "../Toolbar/MobileToolbar";

function HomePage() {
  return (
    <div>
      <div className="">
        <div className="hidden lg:block">
          <Toolbar />
        </div>
        <div className="lg:hidden block">
          <MobileToolbar />
        </div>
      </div>{" "}
      <div className="">
        <ImageSlider />
      </div>{" "}
      <div className="">
        <BharatBiggest />
      </div>
      <div className="">
        <Information />
      </div>
      <div className="">
        <ExamCategories />
      </div>{" "}
      <div className="hidden lg:block">
        <OfflineCenter />
      </div>
      <div className="">
        <Platform />
      </div>{" "}
      <div className="">
        <ResultsContainer />
      </div>{" "}
      <div className="">
        <JoinNow />
      </div>
      <div className="">
        <StudyResources />
      </div>
      <div className="">
        <StudentContainer />
      </div>
      {/* <div className="">
        <IntroContainer />
      </div> */}
      <div className="">
        <EducationalPlatform />
      </div>
      <div className="">
        <LightFooter />
      </div>{" "}
    </div>
  );
}

export default HomePage;
