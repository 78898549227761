import "react-responsive-carousel/lib/styles/carousel.min.css"; // Make sure to use the correct path
import { Carousel } from "react-responsive-carousel";
import { useRef } from "react";

function ImageSlider() {
  const data = [
    {
      img: "https://static.pw.live/5eb393ee95fab7468a79d189/GLOBAL_CMS/ac196fce-b887-4058-8d42-0a18596ace31.webp",
    },
    {
      img: "https://static.pw.live/5eb393ee95fab7468a79d189/GLOBAL_CMS/ac196fce-b887-4058-8d42-0a18596ace31.webp",
    },
    {
      img: "https://static.pw.live/5eb393ee95fab7468a79d189/GLOBAL_CMS/ac196fce-b887-4058-8d42-0a18596ace31.webp",
    },
    {
      img: "https://static.pw.live/5eb393ee95fab7468a79d189/GLOBAL_CMS/ac196fce-b887-4058-8d42-0a18596ace31.webp",
    },
  ];

  const ref = useRef();
  const showNext = () => {
    ref.current.onClickNext();
  };

  const showRevious = () => {
    ref.current.onClickPrev();
  };

  return (
    <div>
      <div className=" relative ">
        <div className="absolute flex w-[98%] justify-between m-auto left-0 right-0 top-0 bottom-0 z-30 ">
          <button className="p-2 rounded-2xl " onClick={showRevious}>
            <img
              src={require("../../Assets/prev-icon.png")}
              alt=""
              className="w-[50%] tablet:w-[75%] lg:w-[90%]"
            />
          </button>
          <button className="p-2 rounded-2xl" onClick={showNext}>
            <img
              src={require("../../Assets/next-icon.png")}
              alt=""
              className="w-[50%] tablet:w-[75%] lg:w-[90%]"
            />
          </button>
        </div>
        <Carousel
          ref={ref}
          centerMode={true}
          showThumbs={false}
          infiniteLoop={true}
          dynamicHeight={false}
          showStatus={false}
          showArrows={false}
          autoPlay={true}
          showIndicators={false}
          className="rounded-md"
          centerSlidePercentage={100}
        >
          {data.map((items, index) => (
            <img
              key={index}
              src={items.img}
              alt={`Slide ${index}`}
              className="h-[8rem] lg:h-[20rem] tablet:h-[12rem]"
              width={100}
              height={24}
              priority
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default ImageSlider;
