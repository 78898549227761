import "./OfflineCenter.css";
function OfflineCenter() {

  return (
    <div className=" w-full    bg-image ">
      <div className="flex justify-center w-full bg-[#313639]/30">
        <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex  flex-col gap-10 justify-between   rounded-lg text-center py-5">
          <div className="text-white ">
            <p className="text-3xl lg:text-4xl">
              Explore Tech-Enabled Offline Vidyapeeth Centres
            </p>
            <p className="text-xl">
              Creating new benchmarks in learning experiences
            </p>
          </div>
          <div className="bg-white p-5 rounded-xl translate-y-14 shadow-lg ">
            <div className="">
              <p className="text-4xl">Find Vidyapeeth Centre in your city</p>
              <p className="text-xl">Available in 105 cities</p>
            </div>
            <div className="flex flex-col md:flex-row gap-5 mt-10">
              <img
                src={require("../../Assets/kota.jpeg")}
                alt=""
                className=" cursor-pointer border lg:w-[50%]"
              />
              <img
                src={require("../../Assets/patana.jpg")}
                alt=""
                className=" cursor-pointer lg:w-[50%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfflineCenter;
