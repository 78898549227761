function JoinNow() {
  return (
    <div className="flex justify-center w-full  my-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex flex-col-reverse lg:flex-row  gap-10 justify-between   rounded-2xl py-5 bg-[#E3E0FE]/40 items-center">
        <div className="w-[90%] lg:w-[60%] lg:ms-10">
          <div className="flex flex-col">
            <p className="text-3xl font-bold ">
              Join 15 Million students on the app today!
            </p>
            <ul className=" list-dics flex flex-col gap-3 my-10">
              <li className="">Live & recorded classes available at ease</li>
              <li className="">Dashboard for progress tracking</li>
              <li className="">
                Millions of practice questions at your fingertips
              </li>
            </ul>
          </div>
          <div className="flex items-center">
            <img
              src={require("../../Assets/playStore.png")}
              alt=""
              className="w-[10rem] cursor-pointer"
            />{" "}
            <img
              src={require("../../Assets/aapStore.png")}
              alt=""
              className="w-[10rem] cursor-pointer"
            />
          </div>
        </div>
        <div className=" w-[95%] lg:w-[20%] me-10">
          <img
            src={require("../../Assets/notification_mobile.png")}
            alt=""
            className=""
          />
        </div>
      </div>
    </div>
  );
}

export default JoinNow;
