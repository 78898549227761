// eslint-disable-next-line
import { FaYoutube } from "react-icons/fa";

function EducationalPlatform() {
  const cardData = [
    { title: "quest4Physics", icon: <FaYoutube />, Subscribers: "1.77M" },
    { title: "quest4Physics", icon: <FaYoutube />, Subscribers: "1.77M" },
    { title: "quest4Physics", icon: <FaYoutube />, Subscribers: "1.77M" },
    { title: "quest4Physics", icon: <FaYoutube />, Subscribers: "1.77M" },
    { title: "quest4Physics", icon: <FaYoutube />, Subscribers: "1.77M" },
    { title: "quest4Physics", icon: <FaYoutube />, Subscribers: "1.77M" },
  ];
  return (
    <div className="flex justify-center w-full  my-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex  flex-col gap-10 justify-between   rounded-lg text-center py-5">
        <div className=" ">
          <p className="text-3xl lg:text-4xl font-bold">
            Join Bharat’s Most Loved Educational Platform Today
          </p>
          <p className="text-sm">
            Explore our 72 YouTube Channels and subscribe to get access to
            quality education for free. Physics Wallah 11.5M Subscribers
            Competition Wallah 2.71M Subscribers
          </p>
        </div>
        <marquee behavior="" direction="">
          <div className="flex gap-5">
            {cardData?.map((card, index) => (
              <div className="bg-[#FFFAF1] flex flex-col items-center p-10 rounded-xl">
                <div className="text-5xl">{card?.icon}</div>
                <div className="">
                  <p className="text-2xl font-bold">{card?.title}</p>
                  <p className="flex items-center gap-4">
                    <p className=""> {card?.Subscribers}</p>{" "}
                    <p className="">Subscribers</p>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </marquee>
      </div>
    </div>
  );
}

export default EducationalPlatform;
