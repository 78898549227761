// import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import NeetCourse from "./Pages/NeetCourse/NeetCourse";
import JeeCourse from "./Pages/JeeCourse/JeeCourse";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/neet" element={<NeetCourse />} />
      <Route path="/jee" element={<JeeCourse />} />
    </Routes>
  );
}

export default App;
