import CustomButton from "../../Shared/CustomButton/CustomButton";
import { IoMdStar } from "react-icons/io";
import { FiPlus } from "react-icons/fi";

function NeetHero() {
  return (
    <div className="flex justify-center ">
      <div className=" w-[90%] md:w-[80%] lg:w-[60%] flex flex-col my-10 ">
        <div className="flex flex-col lg:flex-row justify-between items-center ">
          <div className="lg:w-[45%] flex flex-col gap-3">
            <p className="text-xs font-medium text-[#0A6FFF]">
              FOR BEGINNERS AND EXPERIENCED LEARNERS
            </p>
            <p className="text-3xl font-semibold  font-poppins">NEET</p>{" "}
            {/* <p className="text-xl font-semibold mb-5 font-poppins">
              ( MongoDB, Express.js, Angular.js, & Node.js.)
            </p> */}
            <p className="text-sm">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Dignissimos perspiciatis tempore quidem velit aspernatur incidunt
              quibusdam harum error! Aliquam culpa deserunt non facilis
              reprehenderit repudiandae saepe vel dolor suscipit! Amet! Lorem
              ipsum dolor sit amet consectetur adipisicing elit. Labore
              exercitationem ipsum sed ea consequatur totam repudiandae ipsa
              cupiditate consequuntur dolorem earum maxime officia quibusdam ab
              suscipit unde, temporibus sequi similique! Lorem ipsum dolor sit
              amet consectetur adipisicing elit. Labore delectus sit officiis
              inventore quis perspiciatis, nulla repellendus enim laboriosam ea
              atque cumque eveniet alias laborum similique modi hic, quasi ut.
              lo
            </p>
            <div className="flex flex-col lg:flex-row gap-5 justify-start my-10">
              <CustomButton
                label={`Register Now`}
                className={`bg-purpleColor text-white !mx-0 lg:!text-base !px-10 `}
                onClick={() => {
                  window.location.href = "https://student.quest4physics.com/";
                }}
              />
            </div>
            <div className="bg-[#F3F8FE] p-5 flex items-center rounded-lg ">
              <div className="flex flex-col items-center w-full justify-center font-poppins px-2 ">
                <div className="flex items-center font-semibold text-xl gap-2">
                  <p className="">4.8</p>
                  <IoMdStar className="text-xl text-[#F3A805]" />
                </div>
                <p className="text-xsm w-full  text-center">
                  100+ Learners enrolled
                </p>
              </div>
              <div className="flex flex-col items-center w-full justify-center font-poppins  px-2 border-x-2">
                <div className="flex items-center font-semibold text-xl">
                  <p className="">140</p>
                  <FiPlus />
                </div>
                <p className="text-xsm w-full  text-center">
                  Hours of lectures
                </p>
              </div>
              <div className="flex flex-col items-center w-full justify-center font-poppins px-2 ">
                <div className="flex items-center font-semibold text-xl">
                  <p className="">10</p>
                  <FiPlus />
                </div>
                <p className="text-xsm w-full  text-center">
                  100+ Learners enrolled
                </p>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:w-[50%]">
            <div className="flex items-center justify-center  ">
              <img src={require("../../Assets/course-content.webp")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NeetHero;
