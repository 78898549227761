import ImageSlider from "../ImageSlider/ImageSlider";

function ResultsContainer() {
  const courseName = [
    { name: "IIT - JEE", link: "" },
    { name: "Neet", link: "" },
    { name: "Class - 12", link: "" },
    { name: "Class - 11", link: "" },
  ];

  return (
    <div className="flex justify-center w-full  my-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex  flex-col gap-10 justify-between   rounded-lg text-center py-5">
        <div className=" ">
          <p className="text-3xl lg:text-4xl font-bold">
            Academic Excellence : Results
          </p>
          <p className="text-xl">
            Giving wings to a millions dreams, a million more to go
          </p>
        </div>
        <div className="flex justify-center gap-5 ">
          {courseName?.map((items, index) => (
            <p
              className="uppercase border p-1 px-5 rounded-full text-xsm md:text-base"
              key={index}
            >
              {items?.name}{" "}
            </p>
          ))}
        </div>
        <div className="">
          <ImageSlider />
        </div>
      </div>
    </div>
  );
}

export default ResultsContainer;
