import { Constants } from "../../AllConstant/Constant";
import quote from "../../Assets/quote.svg";

function StudentContainer() {
  return (
    <div className="flex justify-center w-full  my-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex  flex-col gap-10 justify-between   rounded-lg text-center py-5">
        <div className=" ">
          <p className="text-3xl lg:text-4xl font-bold">
            Students ❤️ {Constants?.WEB_NAME}
          </p>
          <p className="text-xl ">Hear from our students</p>
        </div>
        <div className=" p-5 shadow-lg flex flex-col lg:flex-row items-start rounded-xl">
          <div className="lg:w-[40%] ">
            <img
              src={require("../../Assets/chamera-banner.webp")}
              alt=""
              className=""
            />
          </div>
          <div className="lg:w-[60%] lg:px-10 flex flex-col justify-between ">
            <img src={quote} alt="" className="w-[3rem] opacity-10 mb-2" />
            <p className="text-start">
              {Constants?.WEB_NAME} teachers and their guidance plays a very
              important role in achieving my goal. During my preparation, the
              shaurya batch helped me a lot during my journey. Once I started my
              preparation, I never looked back.
            </p>
            <div className="text-start mt-10">
              <p className="text-lg font-bold">Demo Name</p>
              <p className="flex items-center">
                <span className="border-r pe-2">Air </span>
                <span className="ps-2">Defence</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentContainer;
