// eslint-disable-next-line
import { useEffect, useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

function Toolbar() {
  const navigate = useNavigate();
  const [course, setCourse] = useState("");

  const courseArray = [
    { courseName: "Neet", value: "neet" },
    { courseName: "IIT-Jee", value: "jee" },
    // { courseName: "12th" },
    // { courseName: "11th" },
  ];

  useEffect(() => {
    if (course === "neet") {
      navigate(`/neet`);
    }
    if (course === "jee") {
      navigate(`/jee`);
    }
  }, [course]);

  return (
    <div className="flex justify-center w-full">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex gap-10 justify-between  items-center  rounded-lg text-center py-5">
        <div className="flex gap-10">
          <img
            src={require("../../Assets/logo.avif")}
            alt=""
            className="w-[13rem] cursor-pointer"
            onClick={() => navigate("/")}
          />
          <select
            value={course}
            onChange={(e) => {
              setCourse(e.target.value);
            }}
            className="w-[15rem] p-2 border border-[#E4E7EB] rounded-md uppercase "
          >
            <option value="" disabled>
              Choose Course
            </option>
            {courseArray?.map((itmes, index) => (
              <option value={itmes?.value} key={index} className=" uppercase">
                {itmes?.courseName}
              </option>
            ))}
          </select>
        </div>

        <div className="">
          <CustomButton
            label={`Visit Classroom`}
            className={`bg-purpleColor text-white !mx-0 lg:!text-base !px-10 `}
            onClick={() => {
              window.location.href = "https://student.quest4physics.com/";
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
