import Toolbar from "../Toolbar/Toolbar";
import MobileToolbar from "../Toolbar/MobileToolbar";
import LightFooter from "../Footer/LightFooter";
import JeeHero from "./JeeHero";

function JeeCourse() {
  return (
    <div>
      <div>
        <div className="">
          <div className="hidden lg:block">
            <Toolbar />
          </div>
          <div className="lg:hidden block">
            <MobileToolbar />
          </div>
        </div>
        <div className="">
          <JeeHero />
        </div>
        <div className="">
          <LightFooter />
        </div>
      </div>
    </div>
  );
}

export default JeeCourse;
