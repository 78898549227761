// eslint-disable-next-line
import { useEffect, useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
function MobileToolbar() {
  const navigate = useNavigate();
  const [course, setCourse] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  const courseArray = [
    { courseName: "Neet", value: "neet" },
    { courseName: "IIT-Jee", value: "jee" },
    // { courseName: "12th" },
    // { courseName: "11th" },
  ];

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showMenu]);

  useEffect(() => {
    if (course === "neet") {
      navigate(`/neet`);
    }
    if (course === "jee") {
      navigate(`/jee`);
    }
  }, [course]);

  return (
    <div className="flex justify-center w-full">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex gap-10 justify-between  items-center  rounded-lg text-center py-5">
        <div className="flex gap-10">
          <img
            src={require("../../Assets/logo.avif")}
            alt=""
            className="w-[7rem] cursor-pointer"
            onClick={() => navigate("/")}
          />
          <select
            value={course}
            onChange={(e) => setCourse(e.target.value)}
            className="w-[15rem] p-2 border border-[#E4E7EB] rounded-md uppercase "
          >
            <option value="" disabled>
              Choose Course
            </option>
            {courseArray?.map((itmes, index) => (
              <option
                value={itmes?.courseName}
                key={index}
                className=" uppercase"
              >
                {itmes?.courseName}
              </option>
            ))}
          </select>
        </div>

        <div className="">
          <IoMdMenu
            className={`text-3xl`}
            onClick={() => setShowMenu(!showMenu)}
          />
        </div>
        {showMenu && (
          <div className=" absolute  animate-rightPopup z-50 bg-white left-0 right-0 top-0 bottom-0">
            <div className="flex justify-between">
              <p className="p-5 font-bold text-xl">Course</p>
              <IoMdClose
                className={`text-3xl m-5`}
                onClick={() => setShowMenu(false)}
              />
            </div>
            <div className="flex flex-col gap-4 text-start px-5">
              {courseArray?.map((data, index) => (
                <p
                  className=" uppercase"
                  onClick={() => navigate(`/${data?.value}`)}
                >
                  {data?.courseName}
                </p>
              ))}
            </div>
            <CustomButton
              label={`Visit Classroom`}
              className={`bg-purpleColor text-white !mx-0 lg:!text-base !px-10 mt-10 `}
              onClick={() => {
                window.location.href = "https://student.quest4physics.com/";
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MobileToolbar;
