function Information() {
  return (
    <div className="flex justify-center w-full   -translate-y-10">
      <div className="w-[95%] tablet:w-[90%] lg:w-[70%] flex justify-between  bg-white shadow-no-border-shadow-2  rounded-lg">
        <div className="flex flex-col lg:flex-row justify-between w-1/2 p-10 ">
          <div className="lg:w-1/2 flex flex-col gap-5  items-center   justify-between lg:border-r border-[#D9DCE0] ">
            <div className="">
              <img
                src={require("../../Assets/live-streaming.png")}
                alt=""
                className="w-[3rem]"
              />
            </div>
            <div className="text-center">
              <p className="font-medium text-lg">Daily Live</p>
              <p className=" font-normal text-sm">Interactive classes</p>
            </div>
          </div>{" "}
          <div className="lg:w-1/2 flex flex-col gap-5 items-center justify-between lg:border-r border-[#D9DCE0]  ">
            <div className="">
              <img
                src={require("../../Assets/checklist.png")}
                alt=""
                className="w-[3rem]"
              />
            </div>
            <div className="text-center">
              <p className="font-medium text-lg">10 Million +</p>
              <p className=" font-normal text-sm">
                Tests, sample papers & notes
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-10 justify-between w-1/2  p-10 ">
          <div className="lg:w-1/2 flex flex-col gap-5 items-center justify-between lg:border-r border-[#D9DCE0]  ">
            <div className="">
              <img
                src={require("../../Assets/doubt-solving.png")}
                alt=""
                className="w-[3rem]"
              />
            </div>
            <div className="text-center">
              <p className="font-medium text-lg">24 x 7</p>
              <p className=" font-normal text-sm">Doubt solving sessions</p>
            </div>
          </div>{" "}
          <div className="lg:w-1/2 flex flex-col gap-5 items-center  ">
            <div className="">
              <img
                src={require("../../Assets/class-center.png")}
                alt=""
                className="w-[3rem]"
              />
            </div>
            <div className="text-center">
              <p className="font-medium text-lg">100 +</p>
              <p className=" font-normal text-sm">Offline centres</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;
